<template>
  <div class="headerbg">
    <div class="bot">
      <p class="tips">
        * 建议尺寸750px*240px，上传图片不超过1M，支持png、bmg、jpeg、jpg、gif
      </p>
        <div class="itemBox">
            <div class="left">
                 <img :src="header_bg"/>
            </div>
            <div class="right">
                 <div class="inputBox">
                    <el-input v-model="header_bg" disabled placeholder="选择图片"></el-input>
                    <el-button type="primary" @click="chooseImg">选择</el-button>
                </div>
            </div>
        </div>
    </div>
    <!-- 选择图片弹框 -->
    <UploadSource
      v-if="chooseImgFlag"
      @changeStatus="changeStatus"
      :dialogVisible="chooseImgFlag"
      @getSource="getImgRes"
      :maxSize="1024 * 1024"
    ></UploadSource>
  </div>
</template>

<script>
import UploadSource from '@/components/uploadSource'
export default {
  props: ['header_bg'],
  components: {
    UploadSource,
  },
  data() {
    return {
      chooseImgFlag: false,
    }
  },
    watch:{
        header_bg(){
            this.updateData()
        }
    },
  methods: {
       updateData(){
            this.$emit('update-data', this.header_bg)
        },
        changeStatus(val) {
            this.chooseImgFlag = val
        },
        // 拿到图片
        getImgRes(imglist) {
            this.header_bg = imglist[0].path
        },
        // 选择图片
        chooseImg(){
             this.chooseImgFlag = true
        }
  }
}
</script>

<style lang="less" scoped>
.fcccc {
  color: #ccc;
  border: 1px solid #ccc !important;
  cursor: no-drop !important;
}
.headerbg {
  width: 100%;
  margin-right: 30px;
  .bot {
    .tips {
      font-size: 14px;
      color: #9a9a9a;
        margin-top:20px;
    }
    .itemBox {
        width: 100%;
        display: flex;
        height: 100px;
        margin-top: 20px;
        .left {
            height: 100%;
            width: 220px;
            overflow: hidden;
            margin-right:10px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
        }
        .right {
          flex: 1;
          .inputBox {
            height: 40px;
            border: 1px solid #ccc;
            border-radius: 3px;
            display: flex;
            align-items: center;
            position: relative;
          }
        }
      }
  }
}
</style>
