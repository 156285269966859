<template>
  <div class="container">
    <!-- 组件 -->
    <div class="tools">
      <div :class="['toolitem', chooseset == index ? 'tool_active' : '']" v-for="(item, index) in componentArr" :key="index" @click="changeSet(index)">
        <img :src="imgH + item.icon" alt="icon" />
        <p>{{ item.name }}</p>
      </div>
    </div>
    <!-- 展示 -->
    <div class="pane">
      <div class="pane_phone">
        <div class="pane_main">
          <img :src="imgH + 'multiversion_phoneheader2.png'" class="image phoneheader2" />
          <img :src="imgH + 'multiversion_minibutton.png'" class="minibutton" />
          <!-- 头部背景 -->
          <div :class="['headerbg', chooseset == 0 ? 'component_checked' : 'component_canset']" @click="changeSet(0)">
            <img :src="header_bg" />
          </div>
          <div class="content thin_scroll_column">
            <div class="content_main">
              <img :src="shopinfo.logo" alt="logo" class="logo" />
              <div class="store_name">{{ shopinfo.name }}</div>
              <div class="store_description">{{ shopinfo.description }}</div>
              <!-- 优惠券 -->
              <div class="coupon">
                <div class="coupon_icon"><span>券</span></div>
                <div class="coupon_item coupon_on">
                  <i class="iconfont icon-multiversion-coupon-on" :style="{ color: main_color }"></i>
                  <span style="margin-left: 4px">满20减5</span>
                  <span style="margin-left: 24px">领取</span>
                </div>
                <div class="coupon_item coupon_off" :style="{ '--color': main_color }">
                  <i class="iconfont icon-multiversion-coupon-off" :style="{ color: main_color }"></i>
                  <span style="margin-left: 4px">满100减10</span>
                  <span style="margin-left: 10px">已领</span>
                </div>
              </div>
              <!-- 公告栏 -->
              <div :class="['notice', chooseset == 1 ? 'component_checked' : 'component_canset']" :style="{ '--color': notice.color }" @click="changeSet(1)">
                <img :src="notice.icon" alt="icon" v-if="notice.icon" class="notice_icon" />
                <span>{{ notice.text }}</span>
                <div style="flex: 1"></div>
                <img :src="imgH + 'rightArrow_1.png'" alt="rightArrow" class="rightArrow" />
              </div>
              <!-- 会员卡 -->
              <div :class="['card', chooseset == 2 ? 'component_checked' : 'component_canset']" @click="changeSet(2)">
                <div class="card_main">
                  <img :src="card_logo" alt="icon" class="card_logo" />
                  <div style="flex: 1">
                    <div class="card_name">
                      {{ shopinfo.name }}会员卡
                      <img :src="imgH + 'multiversion_cardicon.png'" />
                    </div>
                    <div class="card_text">开通会员立得优惠特权</div>
                  </div>
                  <div class="card_go">立即开通</div>
                </div>
              </div>
              <!-- tab -->
              <div class="tab">
                <div class="tab_index" :style="{ '--color': main_color }">首页</div>
                <router-link class="tab_brand" to="/retailSingle/retailbrandstory">品牌</router-link>
                <div style="flex: 1"></div>
                <div class="tab_search">
                  <img :src="imgH + 'multiversion_search.png'" />
                  <span>搜索</span>
                </div>
              </div>
              <!-- 轮播图 -->
              <div :class="['carousel', chooseset == 3 ? 'component_checked' : 'component_canset']" @click="changeSet(3)">
                <el-carousel height="120px" class="carousel_box">
                  <el-carousel-item v-for="(item, index) in carousel" :key="index">
                    <img :src="item" alt="img_url" />
                  </el-carousel-item>
                </el-carousel>
              </div>
              <!-- 按钮组 -->
              <div :class="['buttons', chooseset == 4 ? 'component_checked' : 'component_canset']" @click="changeSet(4)" :style="{ background: buttons.bgColor }">
                <div class="button">
                  <div class="button_item" v-for="(item, index) in buttons.list.slice(0, buttons.num * 1 > 5 ? buttons.num / 2 : buttons.num)" :key="index">
                    <img :src="item.img" />
                    <span>{{ item.name }}</span>
                  </div>
                </div>
                <div class="button" v-if="buttons.num * 1 > 5" style="margin-top: 15px">
                  <div class="button_item" v-for="(item, index) in buttons.list.slice(buttons.num / 2, buttons.num)" :key="index">
                    <img :src="item.img" />
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </div>
              <!-- 推荐商品 -->
              <div :class="[chooseset == 5 ? 'component_checked' : 'component_canset']" @click="changeSet(5)">
                <template v-for="(item, index) in recommend">
                  <recommend :key="index" :recommend="item"></recommend>
                </template>
              </div>
              <!-- 全部商品 -->
              <div :class="[chooseset == 6 ? 'component_checked' : 'component_canset']" @click="changeSet(6)">
                <allGoods :AllGoods="AllGoods"></allGoods>
              </div>
            </div>
          </div>
          <!-- tabbar -->
          <img :src="imgH + 'multiversion_tabbar.png'" class="image" alt="multiversion_tabbar" />
        </div>
      </div>
    </div>
    <!-- 设置 -->
    <div class="setting">
      <div class="setting_header">{{ componentArr[chooseset].name }}</div>
      <div class="setting_part">
        <headerbg v-if="chooseset == 0" :header_bg="header_bg" @update-data="updateData($event, 'header_bg')"></headerbg>
        <notice v-if="chooseset == 1" :notice="notice" @reset-data="resetData"></notice>
        <headerbg v-if="chooseset == 2" :header_bg="card_logo" @update-data="updateData($event, 'card_logo')"></headerbg>
        <carousel v-if="chooseset == 3" :carousel="carousel"></carousel>
        <buttons v-if="chooseset == 4" :buttons="buttons"></buttons>
        <recommendControl v-if="chooseset == 5" :controlDefault="recommend" @update-data="updateData($event, 'recommend')"></recommendControl>
        <allGoodsControl v-if="chooseset == 6" :controlDefault="AllGoods"></allGoodsControl>
      </div>
    </div>
  </div>
</template>

<script>
import { imgUrlHead } from '@/util/config.js';
import headerbg from '../components/headerbg.vue';
import notice from '../components/notice.vue';
import carousel from '../components/carousel.vue';
import buttons from '../components/button.vue';
import recommend from '../components/recommend.vue';
import recommendControl from '../components/recommendControl.vue';
import allGoods from '../components/allgoods.vue';
import allGoodsControl from '@/views/addPages/uicomponents/CommodityComponents/allGoods/allGoodsControl.vue';
export default {
  components: {
    headerbg,
    notice,
    carousel,
    buttons,
    recommend,
    recommendControl,
    allGoods,
    allGoodsControl,
  },
  data() {
    return {
      imgH: imgUrlHead,
      componentArr: [
        { name: '头部背景', icon: 'headerbg.png' },
        { name: '公告设置', icon: 'noticeBar.png' },
        { name: '会员卡图片', icon: 'btngroup.png' },
        { name: '轮播图', icon: 'banner.png' },
        { name: '按钮组', icon: 'btngroup.png' },
        { name: '推荐商品', icon: 'allGoods.png' },
        { name: '全部商品', icon: 'allGoods.png' },
      ],
      header_bg: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/changImg.png',
      notice: {
        color: '#999999',
        text: '公告：如您在购物的过程中有任何问题，请及时与我们联系，祝您购物愉快！',
        icon: '',
      },
      card_logo: '',
      carousel: ['https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/changImg.png'],
      buttons: {
        num: '10',
        bgColor: '#ffffff',
        list: [
          {
            img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
            name: '按钮文字',
            page: '跳转页面',
          },
          {
            img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
            name: '按钮文字',
            page: '跳转页面',
          },
          {
            img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
            name: '按钮文字',
            page: '跳转页面',
          },
          {
            img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
            name: '按钮文字',
            page: '跳转页面',
          },
          {
            img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
            name: '按钮文字',
            page: '跳转页面',
          },
          {
            img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
            name: '按钮文字',
            page: '跳转页面',
          },
          {
            img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
            name: '按钮文字',
            page: '跳转页面',
          },
          {
            img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
            name: '按钮文字',
            page: '跳转页面',
          },
          {
            img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
            name: '按钮文字',
            page: '跳转页面',
          },
          {
            img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
            name: '按钮文字',
            page: '跳转页面',
          },
        ],
      },
      recommend: [
        {
          class_name: '选择分类',
          class_id: -2,
        },
      ],
      AllGoods: {
        bgColor: '#ffffff',
        showPrice: 0,
        priceColor: '#ff0000',
        carStyle: 0,
        showAll: 0,
        list: [
          {
            class_name: '选择分类',
            class_id: -2,
          },
        ],
      },

      chooseset: 0,
    };
  },
  created() {},
  computed: {
    shopinfo() {
      return this.$parent.shopinfo;
    },
    main_color() {
      return this.$parent.main_color;
    },
  },
  watch: {
    shopinfo(val) {
      this.card_logo = val.logo;
    },
  },
  methods: {
    // 重置数据
    resetData(obj) {
      this[obj.data][obj.attr] = null;
    },
    // 改变设置组件
    changeSet(index) {
      this.chooseset = index;
    },
    updateData(e, str) {
      this[str] = e;
    },
  },
};
</script>

<style scoped lang="less">
.container {
  display: flex;
  height: 100%;
  .tools {
    flex: 0 0 300px;
    background-color: #fafafa;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    .toolitem {
      width: 40%;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      margin-bottom: 20px;
      font-size: 14px;
      box-sizing: initial;
      &:hover {
        background-color: #e8f1fb;
      }
    }
    .tool_active {
      background-color: #e8f1fb;
    }
  }
  .pane {
    flex: 1;
    height: 100%;
    min-width: 411px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    margin-left: 6px;
    .pane_phone {
      width: 411px;
      height: 853px;
      margin-top: 6px;
      background-size: 100% 100%;
      background-image: url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/phonebg.png);
      position: relative;
    }
    .pane_main {
      width: 375px;
      height: 720px;
      margin: 60px 0 0 16px;
      position: relative;
      .phoneheader2 {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 1;
      }
      .minibutton {
        position: absolute;
        top: 30px;
        right: 15px;
        width: 79px;
        height: 30px;
        z-index: 1;
      }
      .headerbg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 120px;
        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(51, 51, 51, 0.6);
        }
      }
      .content {
        overflow-y: auto;
        height: 100%;
        width: 100%;
        .content_main {
          padding-top: 15px;
          background: #ffffff;
          border-radius: 12px 12px 0px 0px;
          position: relative;
          top: 100px;
          z-index: 20;
          position: relative;
          .logo {
            position: absolute;
            width: 64px;
            height: 64px;
            border-radius: 12px;
            top: -15px;
            right: 15px;
          }
          .store_name {
            margin: 0 16px;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #292929;
          }
          .store_description {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            line-height: 19px;
            margin: 4px 15px;
          }
        }
      }
      .coupon {
        margin-top: 6px;
        display: flex;
        padding: 0 15px;
        align-items: center;
        .coupon_icon {
          width: 20px;
          height: 20px;
          background: #fee0b2;
          border-radius: 4px;
          text-align: center;
          line-height: 20px;
          margin-right: 8px;
          span {
            font-size: 12px;
            background-image: -webkit-linear-gradient(left, #fd9073, #ff4c3a);
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .coupon_item {
          width: 100px;
          height: 20px;
          border-radius: 4px;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            right: 29px;
            top: 5px;
            height: 11px;
            width: 0;
            border-right: 1px dashed #ffffff;
            z-index: 1;
          }
          i {
            font-size: 20px;
            position: absolute;
            top: -1px;
          }
          span {
            position: relative;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
            transform: scale(0.83);
            display: inline-block;
          }
        }
        .coupon_on {
          margin-right: 8px;
        }
        .coupon_off {
          &::before {
            border-color: var(--color);
          }
          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;
            background-color: var(--color);
            opacity: 0.1;
          }
          span {
            color: var(--color);
          }
        }
        .coupon_go {
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
          margin-right: 4px;
        }
        img {
          width: 10px;
          height: 10px;
          transform: rotate(90deg);
        }
      }
      .notice {
        padding: 5px 15px;
        margin: 10px 0 5px;
        display: flex;
        align-items: center;
        .notice_icon {
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
        span {
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: var(--color);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .rightArrow {
          width: 16px;
          height: 16px;
          transform: rotateZ(90deg);
        }
      }
      .card {
        padding: 5px 15px;
        .card_main {
          width: 345px;
          height: 50px;
          background: url('https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/multiversion_cardbg.png');
          background-size: contain;
          border-radius: 8px;
          padding: 0 12px;
          display: flex;
          align-items: center;
          .card_logo {
            width: 34px;
            height: 34px;
            border-radius: 5px;
            margin-right: 10px;
          }
          .card_name {
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #292929;
            line-height: 19px;
            display: flex;
            align-items: center;
            img {
              width: 15px;
              height: 13px;
              margin-left: 4px;
            }
          }
          .card_text {
            font-size: 11px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #292929;
            line-height: 19px;
          }
          .card_go {
            width: 74px;
            height: 26px;
            background: #fdca57;
            border-radius: 4px;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            line-height: 26px;
            text-align: center;
          }
        }
      }
      .tab {
        margin: 16px 16px 5px;
        display: flex;
        align-items: center;
        .tab_index {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #292929;
          position: relative;
          margin-right: 45px;
          &::before {
            content: '';
            position: absolute;
            bottom: -12px;
            left: 50%;
            width: 20px;
            height: 2px;
            background: var(--color);
            border-radius: 1px;
            transform: translateX(-10px);
          }
        }
        .tab_brand {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #999999;
          position: relative;
          &::before {
            content: '故事';
            position: absolute;
            width: 32px;
            height: 16px;
            background: #ff7e6d;
            border-radius: 8px;
            top: -6px;
            left: 24px;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            line-height: 16px;
            transform: scale(0.9);
          }
        }
        .tab_search {
          width: 70px;
          height: 32px;
          background: #f5f5f5;
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          img {
            width: 13px;
            height: 12px;
            margin-right: 6px;
          }
        }
      }
      .carousel {
        padding: 10px 15px;
        border-top: 1px solid #f5f5f5;
        img {
          width: 100%;
          height: 100%;
        }
        .carousel_box {
          border-radius: 8px;
          position: relative;
          z-index: 100;
        }
      }
      .buttons {
        padding: 15px;
        .button {
          display: flex;
          justify-content: space-between;
          .button_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              width: 52px;
              height: 52px;
              border-radius: 26px;
              margin-bottom: 6px;
            }
          }
        }
      }

      .image {
        width: 375px;
        height: auto;
      }
    }
  }
  .setting {
    flex-shrink: 0;
    width: 596px;
    height: 100%;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    .setting_header {
      border-bottom: 1px solid #eee;
      height: 70px;
      display: flex;
      align-items: center;
      padding: 0 30px;
    }
    .setting_part {
      font-size: 14px;
      flex: 1;
      // overflow-y: auto;
      margin-right: 20px;
    }
  }
}
</style>
