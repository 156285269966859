import { render, staticRenderFns } from "./recommendControl.vue?vue&type=template&id=632daa94&scoped=true&"
import script from "./recommendControl.vue?vue&type=script&lang=js&"
export * from "./recommendControl.vue?vue&type=script&lang=js&"
import style0 from "./recommendControl.vue?vue&type=style&index=0&id=632daa94&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "632daa94",
  null
  
)

export default component.exports